.c-badge {
	background: #fbb739;
	line-height: 20px;
	border-radius: 20px;
	padding: 0 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;
	align-self: center;
	.c-badge-num {
		color: #ffffff;
		font-size: 12px;
	}
}
.c-badge-no-data {
	background: #f5f5f5;
	border-radius: 20px;
	padding: 0 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 5px;
	align-self: center;
	.c-badge-num-no-data {
		color: #707070;
		font-size: 12px;
	}
}

.status-package {
	font-family: 'Roboto', sans-serif;
	// padding: 1px 10px;
	line-height: 20px;
	font-size: 12px;
	border-radius: 16px;
	padding: 4px 16px;
	border: none !important;
	font-weight: 500;
	margin: 0;
	white-space: nowrap;
	cursor: pointer;
	margin-right: 12px;
	margin-bottom: 12px;
	background-color: #f6f6f6;
	font-weight: 500;
	display: flex;
	align-items: center;

	color: #5a5a5a;
	&:hover {
		background-color: #fcd535;
		color: #1a1a1a !important;
	}
	&.active {
		// background-color: #faa200;
		// color: white;
		background-color: #fcd535;
		color: #1a1a1a;
	}
}

