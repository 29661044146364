//Font Family
$robotofont: 'Roboto', sans-serif;

//Font Size
$txt-size-h1: 24px;
$txt-size-h2: 22px;
$txt-size-h3: 20px;
$txt-size-h4: 18px;
$txt-size-h5: 16px;
$txt-size-h6: 15px;
$txt-size-h7: 14px;
$txt-size-h8: 12px;
$txt-size-h9: 10px;
$txt-size-32: 32px;
//Color
$txt-color-white: #ffffff;
$txt-color-black2: #333333;
$txt-color-black3:#363636;
$txt-color-black: #1a1a1a;
$txt-color-secondary: #707070;
$txt-color-gray: rgba(0, 0, 0, 0.45);
$txt-color-gray2: #bdbdbd;
$txt-color-gray3: #f2f2f2;
$txt-color-gray4: #707070;
$txt-color-gray6: #C7C7C7;
$txt-color-gray7: #7D7E7E;;
$txt-color-gray5: #EBEBEB;
$txt-color-gray8: #5A5A5A;
$txt-color-red: #F5222D;
$txt-color-red2: #FF4559;
$txt-color-red3: #E63D4F;
$txt-color-red4: #BF3443;
$txt-color-orange: #F59F32;
$txt-color-orange1: #FBB739;
$txt-color-orange2: #FCD535;
$txt-color-orange3: #FAA200;
$txt-color-orange4: #FDB924;
$txt-color-orange5: #FFEECE;
$txt-color-orange6: #FCD535;
$txt-color-orange7: #FFF9DF;
$txt-color-blue: #096DD9;
$txt-color-blue1: #303a41;
$txt-color-blue2: #001A4B;
$txt-color-blue3: #0082E5;
$txt-color-blue5: #09B8AF;
$txt-color-blue6: #3340B6;
$txt-color-blue7: #008DF9;
$txt-color-blue8: #339DFF;
$txt-color-green: #09B2AA;
$txt-color-green1: #43B082;
$txt-color-only-staff: #0F98B1;
$txt-color-primary: #F59F32;
$txt-color-order-detail: #F9A400;
$txt-color-order-finance: #FFF7EE;
$txt-color-pink4: #F54255;

//Background Color
$bg-color-primary: #fcd535;
$bg-color-white: #ffffff;
$bg-color-yellow: #fffbe6;
$bg-color-blue: #096dd9;
$bg-color-blue2: #e6f7ff;
$bg-color-blue3: #a6dbff;
$bg-color-gray: #f0f2f5;
$bg-color-gray2: #D9D9D9;
$bg-color-gray3: #F2F2F2;
$bg-color-gray4: #707070;
$bg-color-gray7: #BDBDBD;
$bg-color-gray5: #EBEBEB;
$bg-color-gray6: #C7C7C7;
$bg-color-gray-badge: #F5F5F5;
$bg-color-gray-tab: #F4F6F8;
$bg-color-dark: #011d57;
$bg-color-green: #09b2aa;
$bg-color-black: #000000;
$bg-color-orange: #FFEDEF;
$bg-color-orange1: #FBB739;
$bg-color-orange2: #FFD7A5;
$bg-color-orange3: #FAA200;
$bg-color-orange4: #ffeece;
$bg-color-orange5: #FFF9DF;

$bg-color-blue4: #DDF0FF;
$bg-color-blue5: #09B8AF;
$bg-color-blue6: #E0F9F7;
$bg-color-blue7: #3340B6;
$bg-color-pink:#FFF7EE;
$bg-color-pink1:#FFEED9;
$bg-color-pink2:#FFF6E5;
$bg-color-pink4: #F54255;
$bg-color-pink5: #FFEDEF;
$bg-color-pink6: #FEEDDE;

/***** guide-line *****/

//border
$bd-disabled: #C7C7C7;
$bd-warning: #FF4559;
$txt-warning: #FF4559;
$bd-divider-success-box:#FFA600;
/***** guide-line *****/
// bg
$bg-warning: #FF4559;
$bg-disabled: #f5f5f5;
$bg-secondary-gray: #ECEDF8;

//box-shadow

$box-sd-primary: #ffe3c0;
$box-sd-secondary: #ced1f3;
$box-sd-warning: #FFB2BA;
$bg-color-gray8: #EDF3F7;
$bg-color-orange2: #FCD535;;

//Border Color
