.main-layout {
	.sidebar-left::-webkit-scrollbar {
		display: none;
	}
	.sidebar-left {
		height: 100vh;
		position: fixed;
		left: 0;
		z-index: 999;
		overflow-y: auto;
		overflow-x: hidden;
		-ms-overflow-style: none; // Internet Explorer 10+
		scrollbar-width: none; // Firefox
		box-shadow: 6px 0px 16px rgba(112, 112, 112, 0.1);
		border-radius: 0px 8px 8px 0px;
		& .ant-layout-sider-children {
			overflow-y: scroll;
			overflow-x: hidden;
			scroll-behavior: smooth;
			-ms-overflow-style: none; // Internet Explorer 10+
			scrollbar-width: none; // Firefox
			// background-color: #FBB739;
			box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
			&::-webkit-scrollbar {
				width: 0px;
				background: transparent;
			}
			& > ul {
				position: fixed;
				min-height: 100vh;
				padding: 0 16px;
			}
		}

		&.collapsed {
			& .ant-layout-sider-children {
				// padding-top: 56px;
			}
		}
	}

	.logo {
		height: 32px;
		margin: 16px;
		background: gray;
	}
	.sidebar-left .logo-collapse {
		background-color: #fbb739;
		display: flex;
		height: 64px;
		width: 56px;
		line-height: 64px;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		z-index: 1;
		cursor: pointer;
		transition: all 0.2s;
		position: relative;
		&::after {
			position: absolute;
			content: '';
			border-bottom: 1px solid #ffd78a;
			width: 208;
			bottom: 0;
			left: 0;
		}
	}
	.sidebar-left .logo-expand {
		// position: fixed;
		background-color: #fbb739;
		height: 64px;
		line-height: 64px;
		width: 240px;
		top: 0;
		z-index: 99999;
		cursor: pointer;
		transition: all 0.2s;
		padding: 0 16px;
		& .ant-image {
			display: flex;
			align-items: center;
		}
		position: relative;
		&::after {
			position: absolute;
			content: '';
			border-bottom: 1px solid #ffd78a;
			width: 208px;
			bottom: 0;
			left: 16px;
			right: 16px;
		}
	}

	.header {
		background: #fff;
		position: fixed;
		z-index: 99;
		left: 80px;
		right: 0;
		align-items: center;
		padding-left: 16px;
		padding-right: 12px;
		//box-shadow: 0px 2px 14px #E6E6E6;
		.header-right-content {
			.bell-icon {
				padding: 9.5px;
				box-sizing: border-box;
				margin-top: 2px;
				color: #3340b6;
				font-size: 14px;
				cursor: pointer;
				position: relative;
				margin-right: 22px;
			}
			.is-show-notice {
				background-color: #ecedf8;
				color: #3340b6;
				border-radius: 4px;
				padding: 9.5px;
			}
			.notice-badge {
				background-color: #ff4559;
				border-radius: 100%;
				font-size: 12px;
				line-height: 20px;
				font-family: 'Roboto', sans-serif;
				color: #fff;
				border: 2px solid #ffffff;
				// right: -7px;
				// top: 5px;
				padding: 2px 6px;

				cursor: pointer;
				&-notice-2num {
					border-radius: 10px;
					right: -10px;
				}
				&-notice-99 {
					font-size: 8px;
					right: -14px;
				}
			}

			.noti-count {
				margin-right: 12px;
				& > i {
					color: #3340b6;
				}
				& .ant-scroll-number {
					background-color: #ff4559;
					color: #fff;
					height: 20px;
					padding: 0px 6px;
					line-height: 20px;
					border-radius: 23px;
				}
			}
		}
	}
	.content {
		margin-top: 64px;
		padding: 16px 16px 16px 104px;
		background-color: #edf3f7;
	}
	.menu-group {
		width: 100%;
		align-items: center !important;
		display: flex !important;
	}
	.menu-group-collapse {
		width: 100%;
		align-items: center !important;
		display: flex !important;
		justify-content: center;
		line-height: 20px;
		height: 28px;
	}
	.ant-menu-item {
		color: #1a1a1a;
		// margin: 0 !important;
		transition: none !important;
		margin: 5px 15px;
		a {
			color: #1a1a1a;
		}
		.ant-menu-title-content {
			.wrapper-icon {
				position: absolute;
				top: 0;
				right: 8px;
				left: 10px;
			}
		}
		&:hover {
			background-color: #fff6e5;
			color: #fbb739;
		}
	}

	& .ant-menu-sub {
		&.ant-menu-inline {
			background-color: #fbb739 !important;
		}
	}
	.ant-menu-submenu {
		margin: 0 !important;
		a {
			font-size: 14px;
			line-height: 22px;
			color: #7D7E7E;
			font-family: 'Roboto', sans-serif;
			text-transform: capitalize;
		}
		& .ant-menu-item {
			background-color: #fbb739;
			border-radius: 6px;
			&:hover {
				background-color: #fff;
				& > i {
					color: #fbb739;
				}
				& .ant-menu-title-content > a {
					color: #505050;
				}
			}
			&.ant-menu-item-selected {
				background-color: white;
				color: #fbb739;

				& > i,
				& .ant-menu-title-content > a {
					color: #fbb739;
				}
			}
		}
		.ant-menu-submenu-title {
			display: flex;
			height: auto;
			color: #7d7e7e;
			border-radius: 6px;
			font-size: 12px;
			// background-color: #FBB739;
		}
	}

	& .ant-menu-sub {
		.ant-menu-title-content {
			.wrapper-icon {
				position: absolute;
				top: 0;
				right: 8px;
				left: 10px;
			}
			&:hover {
				& .ant-menu-item .ant-menu-title-content {
					color: #fbb739 !important;
				}
			}
		}
	}
}

.tooltip-custom {
	& .ant-tooltip-inner {
		color: #1a1a1a;
		border-radius: 4px;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		font-family: 'Roboto', sans-serif;
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #fff6e5;
}

.box-description {
	height: auto;
	position: fixed;
	bottom: 70px;
	right: 0;
	padding: 12px;
	z-index: 999;
}

.box-shadow-desc {
	position: relative;
	box-shadow: 0px 2px 8px rgba(17, 17, 17, 0.15);
	background: #fff3e4;
	border: 1px solid #fbb739;
	border-radius: 6px;
	padding: 12px;
	width: 304px;
	&-only {
		background: white;
		border-color: transparent;
	}
	& .fa-xmark {
		position: absolute;
		top: 12px;
		right: 12px;
		color: #b1b1b1;
	}
}

.box-shadow-descriptions {
	box-shadow: 0px 2px 8px 2px rgba(17, 17, 17, 0.15);
	padding: 16px;
	background: white;
	width: 304px;
}

.icons8-shopee {
	align-self: center;
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiN1bmRlZmluZWQ7Ij48cGF0aCBmaWxsPSIjZjQ1MTFlIiBkPSJNMzYuNjgzLDQzSDExLjMxN2MtMi4xMzYsMC0zLjg5Ni0xLjY3OS0zLjk5Ni0zLjgxM2wtMS4yNzItMjcuMTRDNi4wMjIsMTEuNDc3LDYuNDc3LDExLDcuMDQ4LDExIGgzMy45MDRjMC41NzEsMCwxLjAyNiwwLjQ3NywwLjk5OSwxLjA0N2wtMS4yNzIsMjcuMTRDNDAuNTc5LDQxLjMyMSwzOC44MTksNDMsMzYuNjgzLDQzeiI+PC9wYXRoPjxwYXRoIGZpbGw9IiNmNDUxMWUiIGQ9Ik0zMi41LDExLjVoLTJDMzAuNSw3LjM2NCwyNy41ODQsNCwyNCw0cy02LjUsMy4zNjQtNi41LDcuNWgtMkMxNS41LDYuMjYyLDE5LjMxMywyLDI0LDIgUzMyLjUsNi4yNjIsMzIuNSwxMS41eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiNmYWZhZmEiIGQ9Ik0yNC4yNDgsMjUuNjg4Yy0yLjc0MS0xLjAwMi00LjQwNS0xLjc0My00LjQwNS0zLjU3N2MwLTEuODUxLDEuNzc2LTMuMTk1LDQuMjI0LTMuMTk1IGMxLjY4NSwwLDMuMTU5LDAuNjYsMy44ODgsMS4wNTJjMC4xMjQsMC4wNjcsMC40NzQsMC4yNzcsMC42NzIsMC40MWwwLjEzLDAuMDg3bDAuOTU4LTEuNTU4bC0wLjE1Ny0wLjEwMyBjLTAuNzcyLTAuNTIxLTIuODU0LTEuNzMzLTUuNDktMS43MzNjLTMuNDU5LDAtNi4wNjcsMi4xNjYtNi4wNjcsNS4wMzljMCwzLjI1NywyLjk4Myw0LjM0Nyw1LjYxNSw1LjMwOSBjMy4wNywxLjEyMiw0LjkzNCwxLjk3NSw0LjkzNCw0LjM0OWMwLDEuODI4LTIuMDY3LDMuMzE0LTQuNjA5LDMuMzE0Yy0yLjg2NCwwLTUuMzI2LTIuMTA1LTUuMzQ5LTIuMTI1bC0wLjEyOC0wLjExOGwtMS4wNDYsMS41NDIgbDAuMTA2LDAuMDg3YzAuNzEyLDAuNTc3LDMuMjc2LDIuNDU4LDYuNDE2LDIuNDU4YzMuNjE5LDAsNi40NTQtMi4yNjYsNi40NTQtNS4xNThDMzAuMzkzLDI3LjkzMywyNy4xMjgsMjYuNzQxLDI0LjI0OCwyNS42ODh6Ij48L3BhdGg+PC9zdmc+')
		50% 50% no-repeat;
	background-size: 100%;
}
.items-group {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	& .icon:not(:last-of-type) {
		margin-bottom: 12px;
		// line-height: 40px;
		// height: 40px;
		// & i {
		// 	color: #fbb739;
		// }
	}
}

.ant-menu-submenu-title {
	height: auto;
}

.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
	height: auto !important;
}
.ant-menu-item {
	// padding-left: 24px !important;
}

// .ant-menu-submenu-title .ant-menu-item-icon + span {
// 	color: #1a1a1a;
// }
.ant-menu-submenu-popup {
	display: none;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon.anticon-dash {
	line-height: 20px;
	// padding: 4px;
}

.ant-menu-inline-collapsed {
	& .ant-menu-submenu-title {
		justify-content: center;
		padding: 0 !important;
	}
	& .ant-menu-title-content {
		width: 100%;
		& .items-group > .icon {
			width: 100%;
			text-align: center;
			color: #fff;
			&:hover {
				background-color: #fff;
				& > i {
					color: #fbb739 !important;
				}
			}
		}
	}
}

.menu-drawer {
	&.ant-drawer {
		z-index: 994;
		position: fixed;

		& .ant-drawer-content-wrapper {
			width: 270px !important;
			box-shadow: 0px 6px 15px rgb(0 0 0 / 6%);
			position: fixed;
			border-radius: 0px 16px 16px 0px;
			transition-property: transform, box-shadow;
			transition-duration: 500ms;
			transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
			left: 88px;

			& .ant-drawer-content {
				border-radius: 0px 16px 16px 0px;
				.ant-drawer-header {
					padding: 0 20px;
					padding-top: 36px;
					border-bottom: none;
				}

				.ant-drawer-body {
					padding: 0 22px;
				}
			}
		}
	}
}

.menu-drawer-child {
	z-index: 993;
	position: fixed;
	left: calc(88px + 270px);
	& > .ant-drawer-content-wrapper {
		width: 272px;
		border-radius: 0px 16px 16px 0px;
		transition-property: transform, box-shadow;
		transition-duration: 500ms;
		transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
		// left: 396px;
		& .ant-drawer-content {
			border-radius: 0px 16px 16px 0px;
			.ant-drawer-header {
				padding: 0 22px;
				padding-top: 36px;
				border-bottom: none;
			}
		}
	}
}

.menu-active {
	background-color: #fcd535 !important;
	border-radius: 12px;
	color: #363636;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.menu-text-active {
	color: #1a1a1a !important;
}

